<section class="pt-0">
  <div *ngIf="!flightsData" class="row text-center">
    <div class="col text-center">
      <div class="spinner-border">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
  <div *ngIf="!!flightsData" class="row background-white">
    <div class="col-md-12 pb-4 checkin-card boarding-pass-border">
      <div class="row">
        <div class="col-sm-12 mt-3">
          <div class="row logo-line">
            <div class="col mt-2 foot-note text-left date-font-size">
              <span id="scheduled-departure-mobile-{{trip.boardingPassIndex}}">
                {{ trip?.scheduledDeparture?.airportDateTimeString | date:'MMM dd' }}
              </span>
            </div>
            <div class="col text-right">
              <img *ngIf="trip?.tsaPreCheck" [src]='BP_TSA_PRE_LOGO' width="75%" alt="Get TSA Pre Logo" id="tsa-pre-logo-mobile-{{trip.boardingPassIndex}}" />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12 call-out-bold">
              <span id="origin-full-name-mobile-{{trip.boardingPassIndex}}">{{ trip.originFullName }}</span>
              <i class="ha-icon icon-angle-right ml-2 mr-2"></i>
              <span id="destination-full-name-mobile-{{trip.boardingPassIndex}}">{{ trip.destinationFullName }}</span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-4 small text-left" *haText="rendering.props['flight']"></div>
            <div *ngIf="!trip.forConnectionCard" class="col-4 small text-center">
              <span *haText="rendering.props['boarding']"></span>
            </div>
            <div *ngIf="!trip.forConnectionCard" class="col-4 small text-right">
              <span *haText="rendering.props['gateInfo']"></span>
            </div>
          </div>
          <div class="row">
            <div class="col-4 call-out-bold text-left">
              <span id="full-flight-number-mobile-{{trip.boardingPassIndex}}">{{trip.fullFlightNumber}}</span>
            </div>
            <div class="col-4 call-out-bold text-center">
              <span *ngIf="!trip.forConnectionCard"
                id="boarding-time-mobile-{{trip.boardingPassIndex}}">{{ trip.boardingTime | date:'shortTime' }}</span>
            </div>
            <div class="col-4 call-out-bold text-right">
              <span *ngIf="!trip.forConnectionCard" id="gate-info-mobile-{{trip.boardingPassIndex}}"> {{trip.gateInfo}} </span>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-6 small text-left" *ngIf="!trip.forConnectionCard">
              <span id="seat-category-mobile-{{trip.boardingPassIndex}}">{{trip?.seatCategory | uppercase}}</span>
            </div>
            <div class="col-6 small text-right">
              <span *ngIf="trip.loungeStatus">{{trip?.loungeStatus}}</span>
            </div>
          </div>
          <div class="row d-block">
            <div class="col-12">
              <span class="medium call-out-bold" id="passenger-full-name-mobile-{{trip.boardingPassIndex}}">{{trip.passengerFullName}}</span>
            </div>
          </div>
          <div *ngIf="!trip.forConnectionCard; else connection" class="row mt-4">
            <div class="col-6" *ngIf="!trip.forConnectionCard && !this.trip?.isInfant" id="boarding-pass-data-mobile-{{trip.boardingPassIndex}}">
              <img [src]="boardingPassData" width="100%" alt="Boarding Pass Data" />
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-4 small">{{ rendering.props['zoneInfo'] | titlecase }}</div>
                <div class="col-8 small">{{ rendering.props['seatInfo'] | titlecase }}</div>
              </div>
              <div class="row">
                <div class="col-4">
                  <h6 class="bold" *ngIf="!trip.forConnectionCard && trip?.boardingZone; else notAvailable" id="zone-mobile-{{trip.boardingPassIndex}}">
                    {{ rendering.props['zones'][trip?.boardingZone] || trip?.boardingZone }}</h6>
                  <ng-template #notAvailable>
                    <h6 class="bold" id="zone-mobile-{{trip.boardingPassIndex}}">
                      {{rendering.props['notAvailable']}}</h6>
                  </ng-template>
                </div>
                <div class="col-8">
                  <h6 class="bold" *ngIf="trip?.seatNumber !== 'SeeAgent' && !this.trip?.isInfant"
                    id="seat-number-mobile-{{trip.boardingPassIndex}}">{{trip?.seatNumber}}</h6>
                  <span class="bold small" *ngIf="trip?.seatNumber === 'SeeAgent' && !this.trip?.isInfant"
                    id="seat-number-mobile-{{trip.boardingPassIndex}}">{{rendering.props['seeGateAgent']}}</span>
                  <span *ngIf="this.trip?.isInfant" class="body-copy-bold"
                    id="seat-number-mobile-{{trip.boardingPassIndex}}">{{rendering.props['seatInf']}}</span>
                </div>
              </div>
              <div class="row mr-2">
                <div *ngIf="resultData?.results[0]?.isPassTravel && resultData?.results[0]?.isPositiveSpace" class="col-12 upgrade text-center">
                  <span id="upgrade-list-mobile-{{trip.boardingPassIndex}}" class="bold">{{rendering?.props['upgradeList']}}</span>
                </div>
                <div *ngIf="trip.seatClassType" class="col-12 priority text-center">
                  <span id="priority-mobile-{{trip.boardingPassIndex}}" class="bold">{{rendering?.props['prioritySeat']}}</span>
                </div>
              </div>
              <div class="row mr-2 mt-2">
                <div *ngIf="resultData?.results[0]?.isPassTravel && !resultData?.results[0]?.isPositiveSpace" class="col-12 standby text-center">
                  <span id="standby-list-mobile-{{trip.boardingPassIndex}}" class="bold">{{rendering?.props['standbyList']}}</span>
                </div>
              </div>
            </div>
            <div class="col-12 pax-numbers mt-4 pt-4">
              <div class="row">
                <div class="col-5">
                  <div *haText="rendering?.props?.confirmationCode" class="small"></div>
                  <div class="call-out-bold" *haText="resultData?.results[0]?.confirmationCode"></div>
                </div>
                <div class="col-7">
                  <div *haText="rendering?.props?.eTicket" class="small"></div>
                  <div class="call-out-bold" *haText="trip?.ticketNumber"></div>
                </div>
              </div>
            </div>
          </div>
          <ng-template #connection>
            <div class="row mt-4">
              <div class="col-md-12 text-center">
                <span class="medium" id="connection-card-operated-by-mobile-{{trip.boardingPassIndex}}">
                  {{rendering.props['noticeOperatedBy']}} {{trip?.OAText}}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <span *ngIf="trip?.disclosureText" id="connection-card-checkin-mobile-{{trip.boardingPassIndex}}">
                  {{trip?.checkInAtOACounter}}
                </span>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-3">
                <i class="ha-icon icon-attention aloha-pink"></i>
              </div>
              <div class="col-9 pl-0" id="connection-card-notice-mobile-{{trip.boardingPassIndex}}">
                {{rendering?.props['mobileNoticePrompt']}}
              </div>
            </div>
          </ng-template>
          <div *ngIf="trip?.boardingPassUrl && !this.trip?.isInfant && ((osDevice$ | async) === 'iOS' || (osDevice$ | async) === 'Android')">
            <div class="row mt-4 justify-content-center">
              <div class="col-6">
                <a id="mobile-wallet-button" href="{{ trip.boardingPassUrl }}" (click)="logLinkClick()">
                  <img class="mobile-wallet" [ngClass]="(osDevice$ | async) === 'iOS' ? 'apple' : 'google'">
                </a>
              </div>
            </div>
          </div>  
        </div>
      </div>
    </div>
  </div>
</section>
